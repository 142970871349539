import { Button, ButtonProps } from "../Button";
import { Feedback } from "../Feedback";
import { ArrowRightIcon } from "@talladega/icons";
import {
  FileUpload,
  FileUploadPreviewList,
  FileUploadProps,
} from "./FileUpload";
import { useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";

interface FormData {
  files: File[];
}

type Props = {
  documents: File[] | undefined;
  onSubmit: (data: FormData) => void;
  onDeletePreview: (id: number) => void;
  showDeletePreview?: boolean;
  submitButtonProps?: { title: string } & Pick<
    ButtonProps,
    "className" | "spacing" | "rightIcon" | "disabled"
  >;
} & Pick<FileUploadProps, "onChange" | "onError">;

export const FileUploadForm = ({
  documents,
  onDeletePreview,
  onError,
  onSubmit,
  onChange,
  showDeletePreview,
  submitButtonProps,
}: Props) => {
  const { t } = useTranslation(["common-components", "purchase"]);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="files"
        rules={{
          required: {
            value: documents?.length === 0,
            message: t("common-components:required"),
          },
        }}
        render={({ field }) => (
          <FileUpload
            onChange={(files: File[]) => {
              onChange?.(files);
              field.onChange(files);
            }}
            preview
            allowFileTypes={["image/jpeg", "image/png", "application/pdf"]}
            allowMultiple
            onError={onError}
            ref={field.ref}
          />
        )}
      />
      {errors.files && (
        <Feedback status="error">
          <p className="py-2">{errors.files?.message}</p>
        </Feedback>
      )}
      <FileUploadPreviewList
        files={documents || []}
        preview={false}
        showDelete={showDeletePreview}
        onDelete={onDeletePreview}
      />
      <div className="flex justify-center items-center">
        <Button
          className={submitButtonProps?.className || "mt-6"}
          spacing={submitButtonProps?.spacing || "tight-hug"}
          rightIcon={
            submitButtonProps?.rightIcon || (
              <ArrowRightIcon className="h-5 w-5" />
            )
          }
          type="submit"
          disabled={!!submitButtonProps?.disabled}
        >
          {submitButtonProps?.title || t("common-components:next")}
        </Button>
      </div>
    </form>
  );
};
